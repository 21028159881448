<template>
  <img
    :src="loadedSrc"
    :srcset="loadedSrcset"
    :alt="alt"
    :title="alt"
    :class="{
      'img-fluid': fluid,
      'w-100 h-100': !fluid,
    }"
    :width="width"
    :height="height"
    itemprop="image"
    :style="{ aspectRatio: `${width}/${height}` }"
    @error="onError"
  />
</template>

<script>
import { getImagePlaceholder } from '~/utils/index'

export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    srcset: {
      type: String,
      default: undefined,
    },
    placeholderColor: {
      type: String,
      default: '#e2e7ea',
    },
    alt: {
      type: String,
      default: '',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      observer: null,
      loadedSrc: this.getPlaceholder(),
      loadedSrcset: undefined,
      placeholder: '',
    }
  },
  watch: {
    src(newValue) {
      this.loadedSrc = newValue
      this.loadedSrcset = this.srcset
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.onIntersect)
    this.observer.observe(this.$el)
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    onError() {
      this.loadedSrc = this.getPlaceholder()
      this.loadedSrcset = undefined
    },
    onIntersect(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting && this.src) {
          this.loadedSrc = this.src
          this.loadedSrcset = this.srcset
          this.observer.unobserve(this.$el)
        }
      })
    },
    getPlaceholder() {
      return getImagePlaceholder(this.placeholderColor)
    },
  },
}
</script>
